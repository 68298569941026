import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import bild3 from './bild_3.jpg';  // Import des Bildes aus dem src-Ordner

function ContactForm() {
  const [state, handleSubmit] = useForm("mblrykwj");  // Deine Formspree-ID

  if (state.succeeded) {
    return <p>Vielen Dank für Ihre Bestellung. Wir werden diese umgehend versenden.</p>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.formHeader}>Bestellformular</h2> {/* Überschrift hinzugefügt */}
      <div style={styles.productInfo}>
        <div style={styles.productItem}>
          <img src={bild3} alt="Strahlenschutztasche" style={styles.icon} />  {/* Einbindung des Bildes */}
          <strong style={styles.productTitle}>1. Strahlenschutztasche für ein Smartphone</strong>
          <p style={styles.productDescription}>inkl. Versand und Mehrwertsteuer</p>
        </div>
        <p style={styles.totalCost}><strong>Gesamtkosten: 21 Euro</strong></p>
      </div>

      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.fieldContainer}>
          <label htmlFor="vorname" style={styles.label}>Vorname</label>
          <input
            id="vorname"
            type="text"
            name="vorname"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="nachname" style={styles.label}>Nachname</label>
          <input
            id="nachname"
            type="text"
            name="nachname"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="strasse" style={styles.label}>Straße</label>
          <input
            id="strasse"
            type="text"
            name="strasse"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="hausnummer" style={styles.label}>Hausnummer</label>
          <input
            id="hausnummer"
            type="text"
            name="hausnummer"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="postleitzahl" style={styles.label}>Postleitzahl</label>
          <input
            id="postleitzahl"
            type="text"
            name="postleitzahl"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="stadt" style={styles.label}>Stadt</label>
          <input
            id="stadt"
            type="text"
            name="stadt"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="land" style={styles.label}>Land</label>
          <input
            id="land"
            type="text"
            name="land"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="bestellmenge" style={styles.label}>Bestellmenge</label>
          <input
            id="bestellmenge"
            type="number"
            name="bestellmenge"
            min="1"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldContainer}>
          <label htmlFor="message" style={styles.label}>Nachricht (optional)</label>
          <textarea
            id="message"
            name="message"
            style={styles.textarea}
          />
        </div>

        <button type="submit" disabled={state.submitting} style={styles.button}>
          Bestellen
        </button>

        <p style={styles.orderText}>
          Wenn Sie auf 'Bestellen' klicken, senden wir Ihnen Ihre Bestellung mit einer beigelegten Rechnung zu. 
          Sie haben dann 14 Tage Zeit, die Bezahlung durchzuführen. 
        </p>
        <p style={styles.orderText}>
            Bitte haben Sie einen Moment Geduld, bis die Bestätigung für die erfolgreiche Übermittlung des Bestellformulars angezeigt wird.
        </p>

      </form>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  productInfo: {
    backgroundColor: '#ffffff',  // Hintergrundfarbe weiß
    padding: '15px',
    border: '1px solid #FFA500',
    borderRadius: '5px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  productItem: {
    marginBottom: '20px',  // Abstand zwischen Bild und Text vergrößert
  },
  icon: {
    display: 'block',
    margin: '0 auto 20px',  // Abstand zwischen Bild und Text vergrößert
    maxWidth: '100%',  // Bildgröße an den Container anpassen
    height: 'auto',
  },
  productTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  productDescription: {
    color: '#333',
  },
  totalCost: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#333',
    marginTop: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Text linksbündig ausrichten
    maxWidth: '400px',
    margin: 'auto',
  },
  fieldContainer: {
    marginBottom: '15px',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    textAlign: 'left', // Text linksbündig ausrichten
  },
  input: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  textarea: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    fontSize: '16px',
    minHeight: '100px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#FFA500', // Orange Hintergrundfarbe
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '20px', // Abstand zum oberen Element
  },
  orderText: {
    marginTop: '20px',
    color: '#333',
    fontSize: '14px',
  }
};

export default ContactForm;
